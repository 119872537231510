import React, {useState, useEffect, useMemo} from 'react';

import api, { sleep } from "../../../helpers/gestora/api"
import {Col, Row, Badge} from "reactstrap";

import { useLocation, useHistory } from "react-router-dom"

import { useOvermind } from "../../../overmind";
import {Datatable} from "../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../components/Common/Tables/Datatable/CustomHeader";
import moment from "moment"
import {dateBrToISO, MoneyFormat} from "../../../renders/utils";

import CustomForm from "../../../components/Common/CustomForm/Form";
import {
	datePeriodOptions,
	docFundTypes,
	investmentOperationMapped,
	investmentOperationOptions, investmentStatusCanceledReasonMapped,
	investmentStatusMapped,
	investmentStatusOptions, investmentStatusPendingReasonMapped,
	riskProfileMapped,
	riskProfileOptions
} from "../../../constants/formLists";
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";
moment.locale('pt-BR')
import Button from "../../../components/Common/Button/Button"

import InvestmentsView from "../View";

import {parseMoney} from "../../../helpers/parser_helper";
import PageDatatable from "../../../components/Common/Tables/Datatable/PageDatatable";
import {DataBank, DataDate} from "../../../components/Common/Tables/Datatable/DataTypes";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient} from "../../../common/url";
import {AuthInfo, UserCan} from "../../../helpers/gestora/auth";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import {useLocalStorage} from "../../../helpers/gestora/UseLocalStorage";
import ModalForm from "../../../components/Common/Modals/ModalForm";

const defaultDate = [
	moment().startOf('month').format('YYYY-MM-DD'),
	// moment().startOf('year').format('YYYY-MM-DD'),
	moment().format('YYYY-MM-DD')
]

const statusTabs = [
	{id:'all', caption:"Todas"},
	...investmentStatusOptions,
]

//console.log('statusTabs', statusTabs)

export const InvestmentsSearch = () => {
	const formId = 'InvestmentSearch'
	const location = useLocation()
	const history = useHistory()

	const [loading, setLoading] = useState(false);
	const [searchParams, setSearchParams] = useState(null);
	const [filterParams, setFilterParams] = useState({});
	const [filterStatus, setFilterStatus] = useState(history.location?.state?.status || 'all');
	// const [selectedPeriod, setSelectedPeriod] = useState(null)
	const [selectedIds, setSelectedIds] = useState([])
	// const [compactMode, setCompactMode] = useState(true)
	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, true)

	const [changePaymentDate, setChangePaymentDate] = useState(null)

	const [approvingMany, setApprovingMany] = useState(false)
	const [changingPaymentDateMany, setChangingPaymentDateMany] = useState(false)
	const [sendingEmailMany, setSendingEmailMany] = useState(false)
	const [exportingResult, setExportingResult] = useState(false)

	const [viewId, setViewId] = useState(0)


	const app = useOvermind('app')

	const apiEndpoint = {
		path : `admin/investments/search`,
		method : `post`,
	}

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			submitForm, setModelValueByPath,
			setFormState, setFieldState, setFormModel, resetForm,
			updateModelValueValues, incFormValidatedCount, incFormResetCount
		}
	} = useOvermind('forms')

	const investments = useOvermind('investments')

	const handleApproveSelected = () => {
		if (!confirm('Deseja realmente aprovar os itens selecionados?'))
			return

		setApprovingMany(true)

		investments.actions.approveMany(selectedIds)
			.then(res => {
				setSelectedIds([])
				refreshList()
			})
			.catch(e => {
				alert(`Ocorreu um erro ao salvar: ${e.toString()}`)
			})
			.finally(() => {
				setApprovingMany(false)
			})


	}

	const changePaymentDateFields = useMemo(() => [
		{
			id:'title',
			caption:'Alterar data de pagamento',
			type:'title',
			required:true,
			col:12
		},
		{
			id:'date',
			caption:'Data de pagamento',
			type:'date',
			required:true,
			col:12
		},
	], [])


	const handleChangePaymentDate = (values) => {

		setChangingPaymentDateMany(true)
		investments.actions
			.changePaymentDateMany({ids:selectedIds, date:dateBrToISO(values.date)})
			.then(res => {
				setSelectedIds([])
				setChangePaymentDate(null)
				refreshList()
			})
			.catch(e => {
				const error = e?.response?.data?.error
				if (error==='not_business_day') {
					alert(`Não foi possível processar, a data definida não é um dia útil.`)
				} else {
					alert(`Ocorreu um erro ao processar: ${e.toString()}`)
				}
			})
			.finally(() => {
				setChangingPaymentDateMany(false)
			})
	}

	const handleResendEmailSelected = () => {
		if (!confirm('Deseja re-enviar os e-mails selecionados aos planejadores ?'))
			return

		setSendingEmailMany(true)

		investments.actions.reSendEmailMany(selectedIds)
			.then(res => {
				setSelectedIds([])
				refreshList()
			})
			.catch(e => {
				alert(`Ocorreu um erro ao enviar: ${e.toString()}`)
			})
			.finally(() => {
				setSendingEmailMany(false)
			})


	}

	const handleResendEmailToClientSelected = () => {
		if (!confirm('Deseja re-enviar os e-mails selecionados aos clientes ?'))
			return

		setSendingEmailMany(true)

		investments.actions.reSendEmailToClientMany(selectedIds)
			.then(res => {
				setSelectedIds([])
				refreshList()
			})
			.catch(e => {
				alert(`Ocorreu um erro ao enviar: ${e.toString()}`)
			})
			.finally(() => {
				setSendingEmailMany(false)
			})


	}

	const handleExportResult = (evt, fileTitle) => {

		setExportingResult(true)

		api
			.post(`admin/investments/downloadxls`, searchParams)
			.then(res => downloadFileClient(res, fileTitle, Buffer.from(res.data.content.data)))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))


	}

	const refreshList = () => {
		// console.log('model?.period', model?.period)
		setSearchParams({
			...filterParams,
			periodStart : model?.period?.length && model?.period[0],
			periodEnd : model?.period?.length && model?.period[1],
			operation : model?.operation,
			fundId : model?.fundId,
			status : filterStatus || undefined,
		})
	}

	useEffect(() => {
		if (history.location?.state?.period) {
			if (model?.period) {
				refreshList()
			}
		} else {
			refreshList()
		}
	}, [filterStatus, model?.operation, model?.fundId, filterParams, model?.period])

	// useEffect(() => {
	//
	// 	if (history.location?.state?.period && model?.period)
	// 		refreshList()
	//
	// }, [history.location?.state?.period])

	useEffect(() => {
		//alert(history.location?.state?.period)
			setModelValueByPath({formId, path:'period', value:history.location?.state?.period || null})

		if (history.location?.state?.status)
			setModelValueByPath({formId, path:'status', value:history.location?.state?.status})

		// setModelValueByPath({formId, path:'fundId', value:null})

		// setFormModel({formId, model:{
		// 	period : history.location?.state?.period || null,
		// 	status : history.location?.state?.status || null,
		// }})
		// setFormModel({formId, model:{
		// 	period : null,
		// 	fundId : null,
		// 	operation : null,
		// }})
	}, [history.location])

	useEffect(() => {
		updateModelValueValues({formId, values:{
			fundId:null,
			operation:null,
		}})

		// if (!history.location?.state)
	}, [location])


	const handleFilterChange = () => {
		const newParams = {
			...filterParams,
			..._.cloneDeep(model),
			updatedAt : model.updatedAt ? dateBrToISO(model.updatedAt) : null,
			paymentAt : model.paymentAt ? dateBrToISO(model.paymentAt) : null,
			value : parseMoney(model.value) ,
		}
		console.log('handleFilterChange', newParams)
		setFilterParams(newParams)
	}

	const handleClick = row => {
		//alert('click')
		setViewId(row.id)
		// history.push({
		// 	pathname:`/clients/registrationform/${row.id}#${Math.random()}`,
		// 	//state : {clientId:row.id}
		// })
		//console.log(row)
	};

	const columns = useMemo(() => [
		// {
		// 	name: '',
		// 	// eslint-disable-next-line react/display-name
		// 	cell:(row) =>
		// 		<button
		// 			type="button"
		// 			className="btn btn-light m-1 p-2 mt-2 font-weight-normal font-size-12"
		// 			onClick={() => handleClick(row)}
		// 		>
		// 			<i className="fa fa-eye font-size-12 align-middle me-0"></i>
		// 		</button>
		// 	,
		//
		// 	width:'56px',
		// 	grow:0,
		// 	ignoreRowClick: true,
		// 	allowOverflow: true,
		// 	button: true,
		// 	compact : true,
		// 	wrap : true,
		// },
		{
			id : 'investments.id',
			name: <CustomHeader onComplete={handleFilterChange} id={'id'} formId={formId} fieldProps={{type:'number'}}>ID</CustomHeader>,
			grow:0,
			width:'64px',
			selector: row => row?.id,
			sortable: true,
			compact : true,
			wrap : true,

		},
		{
			id : 'investments.created_at',
			name: "Envio",
			//name: <CustomHeader id={'created_at'} formId={formId} fieldProps={{type:'date'}}>Envio</CustomHeader>,
			grow:0,
			width:'96px',
			selector: function A(row) {
				return <DataDate value={row?.created_at}/>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
		{
			id : 'investments.processed_at',
			name: <CustomHeader onComplete={handleFilterChange} id={'updatedAt'} formId={formId} fieldProps={{type:'date'}}>Execução</CustomHeader>,
			grow:0.1,
			width:'96px',
			selector: function A(row) {
				return <DataDate value={row?.processed_at || row?.updated_at}/>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
		{
			id : 'investments.payment_at',
			name: <CustomHeader onComplete={handleFilterChange} id={'paymentAt'} formId={formId} fieldProps={{type:'date'}}>Pagamento</CustomHeader>,
			grow:0.1,
			width:'112px',
			selector: function A(row) {
				return <DataDate value={row?.payment_at}/>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
		{
			id : 'investments.operation',
			name:"OP",
			//name: <CustomHeader id={'operation'} formId={formId} fieldProps={{type:'select2multi', options:investmentOperationOptions}}>OP</CustomHeader>,
			grow:0,
			width:'48px',
			selector: row => investmentOperationMapped[row?.operation].shortCaption,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'investments.fund_id',
		 	name: "Fundo",
			// name: <CustomHeader id={'fund_id'} formId={formId} fieldProps={{type:'select2multi', listId:'Funds'}}>Fundo</CustomHeader>,
			grow:0.1,
			width:'64px',
			selector: row =>  Object.values(docFundTypes).find(el => row.fundTypes?.id==el.id)?.short,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'client.name',
			name: <CustomHeader onComplete={handleFilterChange} id={'name'} formId={formId} fieldProps={{type:'text'}}>Cliente</CustomHeader>,
			grow:1,
			selector: row => row?.name,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'bankAccount',
			name: 'Conta',
			width:'130px',
			grow:0.7,
			selector: function A(row) {
				return <DataBank value={row?.bank_data}/>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
		{
			id : 'risk_profile',
			name: <CustomHeader onComplete={handleFilterChange}  id={'risk_profile_category_id'} formId={formId} fieldProps={{type:'select2multi', options:riskProfileOptions}}>Perfil</CustomHeader>,
			grow:0.7,
			width:'128px',
			selector: row => riskProfileMapped[row?.user_data?.riskProfileCategory?.id]?.caption,
			sortable: false,
			omit : compactMode,
			compact : true,
			wrap : true,
		},
		{
			id : 'term',
			name: 'Termo',
			grow:0.1,
			width:'72px',
			selector: row => (row.termSigned || row.termSignedAtZapsign) ? 'S' : 'N',
			sortable: false,
			omit : compactMode,
			compact : true,
			wrap : true,
		},
		{
			id : 'desenquadramento',
			name: 'Desenq.',
			grow:0.1,
			width:'72px',
			selector: row => row.termMismatch ? 'S' : 'N',
			sortable: false,
			omit : compactMode,
			compact : true,
			wrap : true,
		},
		{
			id : 'fiduc_code',
			name: '#Fiduc',
			grow:0.2,
			width:'96px',
			selector: row => `FIDUC${row?.id}`,
			sortable: false,
			omit : compactMode,
			compact : true,
			wrap : true,
		},
		{
			id : 'investments.status',
			name: "Status",
			//name: <CustomHeader id={'status'} formId={formId} fieldProps={{type:'select2multi', options:investmentStatusOptions}}>OP</CustomHeader>,
			width:'128px',
			grow:0.3,
			compact : true,
			wrap : true,
			selector: function A(row) {
				return <Badge
					data-tag="allowRowEvents"
					className={
						"m-0 p-2 font-weight-500 pt-2 pb-2 text-uppercase font-size-em-3 badge-soft-" + investmentStatusMapped[row?.status]?.color
					}
					style={{fontSize:'9px'}}
					color={investmentStatusMapped[row?.status]?.color}
					pill
				>
					{investmentStatusMapped[row?.status]?.caption}
				</Badge>
			},
			sortable: true,
		},
		{
			id : 'value',
			name: <CustomHeader onComplete={handleFilterChange} id={'value'} formId={formId} fieldProps={{type:'money'}}>Valor</CustomHeader>,
			width:'128px',
			grow:0.3,
			selector: row => MoneyFormat(row?.value),
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'reason',
			//name: <CustomHeader id={'reason'} onComplete={handleFilterChange} formId={formId} fieldProps={{type:'text'}}>Motivo</CustomHeader>,
			name: "Motivo",
			width:'128px',
			grow:0.5,
			selector: row => row?.status=='created'
				? null
				: (row?.status=='pending'
					? investmentStatusPendingReasonMapped[row?.pendingReason]?.caption
					: investmentStatusCanceledReasonMapped[row?.canceledReason]?.caption
				)
			,
			sortable: false,
			omit : compactMode,
			compact : true,
			wrap : true,
		},
		{
			id : 'empty',
			name: ' ',
			width:'128px',
			grow:0.5,
			selector: ' ',
			omit : compactMode,
			compact : true,
			wrap : true,
		},
	], [compactMode]);

	const searchFields = useMemo(() => [
		{
			id:formId,
			fields : [
				{
					id:'period',
					caption:'Período',
					type:'daterange',
					options:datePeriodOptions,
					emptyOptionText:<b>Tudo</b>,
					//noEmptyOption:true,
					required:true,
					col:4
				},
				{
					id:'fundId',
					caption:'Fundo',
					type:'select2',
					//noEmptyOption:true,
					emptyOptionText : <b>Todos</b>,
					listId:'InvestmentFunds',
					required:true,
					col:4
				},
				{
					id:'operation',
					caption:'Operação',
					type:'select2',
					//noEmptyOption:true,
					emptyOptionText : <b>Todos</b>,
					options:investmentOperationOptions,
					required:true,
					col:4
				},
			],
		},
	], []);

	//console.log(orderDirection)
	return (<>
		<ModalForm
			data={selectedIds}
			isVisible={!!changePaymentDate}
			fields={changePaymentDateFields}
			confirmMessage={`Deseja mesmo alterar a data de resgate das movimentações selecionadas?`}
			modalStyle={{
				maxWidth : '50%',
			}}
			onCancel={() => {
				setChangePaymentDate(null)
			}}
			onConfirm={handleChangePaymentDate}
		/>
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Movimentações"  >
				</DefaultPageHeader>
			}
		>
			<CustomForm
				className="needs-validation "
				formId={formId}
				model={model}
				validateOnLoad={false}
			>
				{viewId ?
				<InvestmentsView
					formId={formId}
					viewId={viewId}
					onConfirm={() => {
						setViewId(0)
						refreshList()
					}}
					onCancel={() => {
						setViewId(null)
					}}
				/> : null}

				<PageDatatable
					formId={formId}
					tabs={statusTabs}
					activeTab={filterStatus}
					onTabChange={tabId => setFilterStatus(tabId)}
					TabActions={<>
						<UserCanAll permission="investments.edit">
						<Button
							loading={changingPaymentDateMany}
							disabled={!selectedIds.length}
							colorIcon={true}
							className="btn btn-outline-danger  m-1 btn-xs"
							// disabled={currentScreen==0}
							onClick={() => setChangePaymentDate(true)}>
							<i className="m-1 bx bx-calendar align-middle"></i>
							Alterar Dt Pagamento
						</Button>
						<Button
							loading={approvingMany}
							disabled={!selectedIds.length}
							colorIcon={true}
							className="btn btn-outline-danger  m-1 btn-xs"
							// disabled={currentScreen==0}
							onClick={handleApproveSelected}>
							<i className="m-1 bx bx-check-square align-middle"></i>
							Aprovar em lote
						</Button>
						<Button
							loading={sendingEmailMany}
							disabled={!selectedIds.length}
							colorIcon={true}
							className="btn btn-outline-primary  m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={handleResendEmailSelected}>
							<i className="m-1 fa fa-mail-bulk align-middle"></i>
							Enviar e-mail
						</Button>
						</UserCanAll>

						{AuthInfo().id=='30' ?
						<Button
							loading={sendingEmailMany}
							disabled={!selectedIds.length}
							colorIcon={true}
							className="btn btn-outline-primary  m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={handleResendEmailToClientSelected}>
							<i className="m-1 fa fa-mail-bulk align-middle"></i>
							Enviar e-mail para clientes
						</Button> : null}
						<Button
							loading={exportingResult}
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={handleExportResult}>
							<i className="m-1 fa fa-file-excel align-middle"></i>
							Exportar
						</Button>
						<Button
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={() => setCompactMode(!compactMode)}>
							<i className={`m-1 fa ${compactMode ? "fa-eye-slash" : "fa-eye"} align-middle`}></i>
						</Button>
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							// OnInputValueChange={handleInputValueChange}
							tabId={formId}
						/>
					}
				>
					<Datatable
						selectableRows={UserCan('investments.edit')}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						onRowClicked={(row, e) => handleClick(row)}
						keyField={formId}
						formId={formId}
						columns={columns}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
		</>
	);
};

export default {
	title: 'Pagination/Remote',
	component: InvestmentsSearch,
};
